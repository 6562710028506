import React, { useState, useMemo } from "react";
import {
    TextField,
    Checkbox,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    Box,
    Button,
    Popover,
    Tooltip,
} from "@mui/material";
import { CalendarToday, ExpandMore } from "@mui/icons-material";
import moment from "moment";

const TherapyTable = ({ data, handleSelectRow, selectedRows, dateRange, handleSetDateRange }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [expandedRow, setExpandedRow] = useState(null);
    const rowsPerPage = 10;

    // Parse "Value" field
    const parseValueField = (valueField) => {
        const parsedValues = {};
        if (valueField && typeof valueField === "string") {
            valueField.split(",").forEach((item) => {
                const [key, val] = item.split(":");
                parsedValues[key.trim()] = val?.trim();
            });
        }
        return parsedValues;
    };

    const processedData = useMemo(() => {
        return data.map((row) => ({
            ...row,
            ...parseValueField(row.Value),
        }));
    }, [data]);

    const filteredData = useMemo(() => {
        return processedData.filter((row) => {
            const matchesSearch =
                row["Event ID"]
                    ?.toString()
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                row["Event Type"]?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                row["Log Type"]?.toLowerCase().includes(searchQuery.toLowerCase());

            const matchesFromDate = dateRange.from
                ? moment(row["Time Stamp"], "YYYY-MM-DD").isSameOrAfter(moment(dateRange.from, "YYYY-MM-DD"))
                : true;

            const matchesToDate = dateRange.to
                ? moment(row["Time Stamp"], "YYYY-MM-DD").isSameOrBefore(moment(dateRange.to, "YYYY-MM-DD"))
                : true;

            return matchesSearch && matchesFromDate && matchesToDate;
        });
    }, [processedData, searchQuery, dateRange]);

    const currentPageData = useMemo(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        return filteredData.slice(startIndex, startIndex + rowsPerPage);
    }, [filteredData, currentPage]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // const handleDateRangeChange = ({ from, to }) => {
    //     setDateRange({ from, to });
    // };


    return (
        <Box
            sx={{
                p: 0.5,
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#fff",
            }}
        >
            {/* Pagination */}
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <Pagination
                    count={Math.ceil(filteredData.length / rowsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                />
            </Box>
            {/* Table */}
            <Box style={{ justifyContent: 'space-around' }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        // fontSize: "60%",
                        // gap: 1.5,
                        boxShadow: "inherit",
                        marginRight: 3,
                        margin: 2,
                        fontFamily: "fangsong",
                        flexGrow: 1, justifyContent: 'space-around'
                    }}
                >
                    <p>#</p>
                    <p>Cycles</p>
                    <p>Mode</p> <p>Time</p>
                </Box>
                <Box sx={
                    { width: "100%" }
                }>
                    {currentPageData.length > 0 ? (
                        currentPageData.map((row, index) => (
                            <Accordion
                                key={index}
                                expanded={expandedRow === row["Event ID"]}
                            // style={{ padding: "0px 1px" }}
                            >
                                <AccordionSummary
                                    // expandIcon={<ExpandMore />}
                                    sx={{
                                        backgroundColor:
                                            expandedRow === row["Event ID"] ? "#f0f8ff" : "#ffffff",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            // flexDirection: "column",
                                            // alignItems: "flex-start",
                                            gap: 1,
                                            flexGrow: 1,
                                            width: "100%",
                                            // paddingLeft: "2px",
                                        }}
                                    >
                                        {/* Top row with checkbox and details */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                                width: "100%",
                                            }}
                                            onClick={(e) => e.stopPropagation()} // Prevent expanding when clicking here
                                        >
                                            <Checkbox
                                                inputProps={{
                                                    "aria-label": `Select row with Event ID ${row["Event ID"]}`,
                                                }}
                                                style={{ padding: 0, margin: 0 }}
                                                checked={selectedRows.includes(row["Event ID"])}
                                                onChange={(e) => {
                                                    e.stopPropagation(); // Prevent expanding when clicking the checkbox
                                                    handleSelectRow(row);
                                                }}
                                            />
                                            {/* <small >{row["Event ID"]}</small> */}
                                            <small>{row["N_C"]}</small>

                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                                width: "100%",
                                            }}
                                            onClick={(e) => e.stopPropagation()} // Prevent expanding when clicking here
                                        >
                                            <small >{row["MODE"]}</small>

                                        </Box>
                                        {/* Bottom row with timestamp */}
                                        <Box
                                            sx={{
                                                width: "100%",
                                                // textAlign: "left",
                                                paddingLeft: "2px",
                                                flexDirection: "row"
                                            }}
                                            onClick={(e) => e.stopPropagation()} // Prevent expanding when clicking here
                                        >
                                            <small style={{ fontSize: "80%" }}>
                                                {row["Time Stamp"]
                                                    ? moment(row["Time Stamp"]).format("DD-MMM-YYYY HH:mm:ss")
                                                    : "Invalid Date"}
                                            </small>


                                        </Box>
                                        <div
                                            sx={{
                                                width: "100%",
                                                // textAlign: "left",
                                                // paddingLeft: "2px",
                                                flexDirection: "row"
                                            }}
                                            onClick={(e) => e.stopPropagation()} // Prevent expanding when clicking here
                                        >
                                            <ExpandMore onClick={() =>
                                                setExpandedRow(
                                                    expandedRow === row["Event ID"] ? null : row["Event ID"]
                                                )
                                            } />


                                        </div>
                                    </Box>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            borderRadius: "8px",
                                            overflow: "hidden",
                                            border: "1px solid #ddd",
                                        }}
                                    >
                                        {/* Header Row */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                backgroundColor: "#f0f0f0",
                                                padding: "8px 16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    flex: 1,
                                                    textAlign: "right",
                                                    fontSize: "85%",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Pressure
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    flex: 1,
                                                    textAlign: "right",
                                                    fontSize: "85%",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Duration
                                            </Typography>
                                        </Box>

                                        {/* Data Rows */}
                                        {["Pause", "Inspiratory", "Expiratory"].map(
                                            (label, index) => (
                                                <Box
                                                    key={label}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        padding: "8px 16px",
                                                        backgroundColor:
                                                            index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Zebra striping
                                                        borderBottom: "1px solid #ddd",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            flex: 1,
                                                            textAlign: "left",
                                                            fontWeight: "bold",
                                                            color: "#555",
                                                        }}
                                                    >
                                                        {label}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ flex: 1, textAlign: "right", color: "#333" }}
                                                    >
                                                        {
                                                            row[
                                                            `${label === "Pause"
                                                                ? "P_P"
                                                                : label === "Inspiratory"
                                                                    ? "I_P"
                                                                    : "E_P"
                                                            }`
                                                            ]
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ flex: 1, textAlign: "right", color: "#333" }}
                                                    >
                                                        {(
                                                            row[
                                                            `${label === "Pause"
                                                                ? "P_T"
                                                                : label === "Inspiratory"
                                                                    ? "I_T"
                                                                    : "E_T"
                                                            }`
                                                            ] / 10
                                                        ).toFixed(1)}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </Box>

                                    {Object.entries(row)
                                        .filter(([key]) => !key.endsWith("_P") && !key.endsWith("_T")) // Exclude Pressure/Duration Keys
                                        .map(([key, value], index) => (
                                            <Box
                                                key={key}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "4px 8px", // Reduced padding for compact layout
                                                    borderBottom: "1px solid #ddd",
                                                }}
                                            >
                                                <Tooltip title={key.replace(/_/g, " ")} arrow>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            flex: 1,
                                                            fontWeight: "bold",
                                                            color: "#555",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            fontSize: "75%", // Compact font size
                                                        }}
                                                    >
                                                        {key.replace(/_/g, " ")}
                                                    </Typography>
                                                </Tooltip>
                                                <Tooltip title={value} arrow>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            flex: 2,
                                                            textAlign: "right",
                                                            color: "#333",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            fontSize: "75%", // Compact font size
                                                        }}
                                                    >
                                                        {value}
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                        ))}

                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <Typography textAlign="center" color="textSecondary">
                            No data available
                        </Typography>
                    )}
                </Box>

            </Box>


        </Box>
    );
};

export default TherapyTable;
