import React from "react";
import "./Loader.css"; // Create a separate CSS file for styles
import { Box, Skeleton, Accordion, Typography } from "@mui/material";
const Loader = () => {
    return (
        <div className="flex">
            <Box
                sx={{
                    p: 0.5,
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    width: "13vw"
                }}
            >
                {/* Pagination Skeleton */}
                <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                    <Skeleton variant="rectangular" width="200px" height="40px" />
                </Box>

                {/* Table Skeleton */}
                <Box sx={{ mt: 2 }}>
                    {[...Array(5)].map((_, index) => (
                        <Accordion
                            key={index}
                            sx={{
                                mb: 2,
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                backgroundColor: "#f9f9f9",
                                boxShadow: "none",
                            }}
                        >
                            {/* Accordion Summary Skeleton */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                    p: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        width: "100%",
                                    }}
                                >
                                    <Skeleton variant="circular" width={24} height={24} />
                                    <Skeleton variant="text" width="50px" height="20px" />
                                    <Skeleton variant="text" width="80px" height="20px" />
                                    <Skeleton variant="circular" width={20} height={20} />
                                </Box>
                                <Skeleton variant="text" width="120px" height="15px" />
                            </Box>

                            {/* Accordion Details Skeleton */}
                            <Box sx={{ p: 2 }}>
                                {/* Header Row Skeleton */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        backgroundColor: "#f0f0f0",
                                        p: "8px 16px",
                                        mb: 1,
                                    }}
                                >
                                    <Skeleton variant="text" width="100px" height="15px" />
                                    <Skeleton variant="text" width="100px" height="15px" />
                                </Box>

                                {/* Data Rows Skeleton */}
                                {[...Array(3)].map((_, i) => (
                                    <Box
                                        key={i}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            p: "8px 16px",
                                            backgroundColor: i % 2 === 0 ? "#f9f9f9" : "#ffffff",
                                        }}
                                    >
                                        <Skeleton variant="text" width="80px" height="15px" />
                                        <Skeleton variant="text" width="50px" height="15px" />
                                        <Skeleton variant="text" width="50px" height="15px" />
                                    </Box>
                                ))}
                            </Box>
                        </Accordion>
                    ))}
                </Box>
            </Box>


            <div className="loader shimmer">
                <div className="scene">
                    <div className="wizard">
                        <div className="body-loader"></div>
                        <div className="right-arm">
                            <div className="right-hand"></div>
                        </div>
                        <div className="left-arm">
                            <div className="left-hand"></div>
                        </div>
                        <div className="head">
                            <div className="beard"></div>
                            <div className="face">
                                <div className="adds"></div>
                            </div>
                            <div className="hat">
                                <div className="hat-of-the-hat"></div>
                                <div className="four-point-star --first"></div>
                                <div className="four-point-star --second"></div>
                                <div className="four-point-star --third"></div>
                            </div>
                        </div>
                    </div>
                    <div className="objects">
                        <div className="square"></div>
                        <div className="circle"></div>
                        <div className="triangle"></div>
                    </div>
                </div>
                <div className="progress"></div>
                <div className="noise"></div>
            </div></div>
    );
};

export default Loader;
