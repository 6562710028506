import React, {
    forwardRef,
    useRef,
    useState,
    useImperativeHandle,
} from "react";
import Plot from "react-plotly.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import "./styles.css";
import logoR from "./abm.png";
import logoL from "./arc.png";
import { Box } from "@mui/material";
import { Drawer, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

import {
    Add,
    Download,
    ZoomIn,
    Delete,
    Rotate90DegreesCw,
} from "@mui/icons-material";
import Loader from "./loader";
const MultiSubplotsStacked = forwardRef(
    (
        { totalTherpies,
            setLoadingAutoPdf,
            setHidePreview,
            data,
            hidePreview,
            startDate,
            endDate,
            averageValues,
            serialNumber,
            therapiesPerGraph,
            handleSelectChange,
            handleGraphLoading
        },
        ref
    ) => {
        // const [rangeCount, setRange] = useState(0)
        const plotContainerRef = useRef(null);
        const [reportImages, setReportImages] = useState([]);
        const [summaries, setSummaries] = useState([]);
        const [updatingReport, setUpdatingReport] = useState(false);
        const [selectedImage, setSelectedImage] = useState(null);
        const [zoomLevel, setZoomLevel] = useState(1);
        const [sidebarWidth, setSidebarWidth] = useState(30);

        let pdfName;
        const handleParentReport = () => {
            if (reportImages.length > 0) {
                setHidePreview(true);
                setLoadingAutoPdf(false);

            } else {
                addToReport();
                setLoadingAutoPdf(false);
            }
        };
        const addToReport = async () => {
            setUpdatingReport(true);
            if (plotContainerRef.current) {
                await html2canvas(plotContainerRef.current, { scale: 2 })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/png");
                        setReportImages([...reportImages, imgData]);
                        setTimeout(() => {
                            setUpdatingReport(false);
                        }, 10);
                        if (reportImages.length > 0) setHidePreview(true);
                        setLoadingAutoPdf(false);
                    })
                    .catch((err) => {
                        console.error("Error capturing chart:", err);
                    });
            }
        };
        // Expose the function to the parent via the ref
        useImperativeHandle(ref, () => ({
            callChildFunction: downloadReport,
            addToReport,
            handleParentReport,
        }));
        function formatDate(timestamp, time = false) {
            const date = new Date(timestamp); // Convert timestamp to Date object
            const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day
            const month = date.toLocaleString('en-US', { month: 'short' }); // Short month name
            const year = date.getFullYear(); // Full year
            if (!time) return `${day} ${month}, ${year}`
            else if (time) {
                const hours = String(date.getHours()).padStart(2, '0'); // 2-digit hour
                const minutes = String(date.getMinutes()).padStart(2, '0'); // 2-digit minute
                const seconds = String(date.getSeconds()).padStart(2, '0'); // 2-digit second
                return `${day} ${month}, ${year} ${hours}:${minutes}:${seconds}`;
            }
        }
        const downloadReport = async () => {
            setLoadingAutoPdf(true); // Start loading

            const pdf = new jsPDF("p", "pt", "a4");
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const margin = 60;

            // Add Header with Logos and Text
            function addHeaderWithLogosAndText(pdf) {
                const logoWidth = 40 * 2; // Double the width
                const logoHeight = 40 * 1.8; // 1.8 times the height
                pdf.addImage(logoL, "PNG", margin, 20, logoWidth, logoHeight);
                // pdf.addImage(logoR, 'PNG', pageWidth - margin - logoWidth - 10, 20, logoWidth + 10, logoHeight + 10);
                pdf.setFontSize(18);
                const title = "BiWaze® Cough Report";

                // Calculate dimensions for proper centering
                const titleX = pageWidth / 2 - 40;
                const titleY = 50;

                // Render the main text
                // pdf.text(title, titleX, titleY, {
                //     align: "center"
                // });
                pdf.text("BiWaze", titleX - 15, titleY, { align: "center" });

                // Render superscript (adjust positioning as needed)
                pdf.setFontSize(10); // Smaller font for superscript
                pdf.text("®", titleX + 16, titleY - 5); // Adjust offset for superscript
                pdf.setFontSize(18); // Reset font size
                pdf.text("Cough Report", titleX + 80, titleY, { align: "center" });
                pdf.setFontSize(12);
                // pdf.text("Generated by Arc Connect", pageWidth / 2, 70, { align: "center" });
            }

            // Add Watermark Logo
            function addWatermarkLogo(pdf) {
                const logoWidth = 250;
                const logoHeight = 150;
                const centerX = (pageWidth - logoWidth) / 2;
                const centerY = (pageHeight - 20 - logoHeight) / 2;
                pdf.setGState(new pdf.GState({ opacity: 0.5 })); // Set reduced opacity
                pdf.addImage(logoL, "PNG", centerX, centerY, logoWidth, logoHeight);
                pdf.setGState(new pdf.GState({ opacity: 1 })); // Reset opacity
            }

            // Add Footer
            function addFooter(pdf, pageNumber) {
                pdf.setFontSize(10);
                pdf.text(
                    "Confidential Report - Do not distribute by Arc connect",
                    margin,
                    pageHeight - 20
                );
                pdf.text(`Page: ${pageNumber}`, pageWidth - margin, pageHeight - 20, {
                    align: "right",
                });
            }

            // Add Date Range
            function addDateRange(pdf, startDateTime, endDateTime) {
                const options = { year: "numeric", month: "long", day: "numeric" };
                const startDate = new Date(data.Cycle_P_P[0]?.x[0]).toLocaleDateString(
                    "en-US",
                    options
                );
                const endDate = new Date(
                    data.Cycle_P_P[0]?.x[data.Cycle_P_P[0]?.x.length - 1]
                ).toLocaleDateString("en-US", options);

                pdf.setFontSize(12);
                pdfName = `BiWazeCough_${serialNumber}_TherapyReport_${formatDate(data.Cycle_P_P[0]?.x[0])}-${formatDate(data.Cycle_P_P[0]?.x[data.Cycle_P_P[0]?.x.length - 1])}.pdf`;
                if (startDate !== endDate) {
                    pdf.text(`Report Date: ${startDate}`, pageWidth / 2, 90, {
                        align: "center",
                    });
                    // pdf.text(`${startDate} `, pageWidth / 2, 130, { align: "center" });
                } else {
                    pdf.text(
                        `Report Date Range: ${startDate} to ${endDate}`,
                        pageWidth - margin,
                        90,
                        { align: "right" }
                    );

                }
            }
            function addTableAndSummary(pdf) {
                addHeaderWithLogosAndText(pdf);
                addDateRange(pdf, startDate, endDate);

                const keyStyle = {
                    fillColor: "#e8e8e8",
                    textColor: 0,
                    fontSize: 10,
                    fontStyle: "normal",
                    halign: "left",
                };

                const valueStyle = {
                    fillColor: "#fff",
                    textColor: 0,
                    fontSize: 10,
                    halign: "center",
                };

                const productDetails = [
                    [
                        { content: "Product", styles: keyStyle },
                        { content: "Biwaze® Cough", styles: valueStyle },
                        { content: "Serial Number", styles: keyStyle },
                        { content: serialNumber, styles: valueStyle },
                    ],
                    [
                        { content: "Hour Meter Reading", styles: keyStyle },
                        { content: averageValues["HMR"], styles: valueStyle },
                        { content: "", styles: keyStyle }, // Placeholder for alignment
                        { content: "", styles: valueStyle },
                    ],
                ];

                const statsDetails = [
                    [
                        { content: "Average therapy cycles per session", styles: keyStyle },
                        { content: averageValues["average_NC"], styles: valueStyle },
                        { content: "Average Expiratory Volume", styles: keyStyle },
                        { content: averageValues["average_EV"], styles: valueStyle },
                    ],
                    [
                        { content: "Average therapy sessions per day", styles: keyStyle },
                        { content: averageValues["average_Therapy"], styles: valueStyle },
                        { content: "Standard Deviation of Expired Volume", styles: keyStyle },
                        { content: averageValues["sdExpVol"], styles: valueStyle },
                    ],
                    [
                        { content: "Total Therapy Sessions", styles: keyStyle },
                        { content: totalTherpies, styles: valueStyle },
                        { content: "Average Peak Cough Flow", styles: keyStyle },
                        { content: averageValues["average_PF"], styles: valueStyle },
                    ],
                    [
                        { content: "Number of days with no therapy", styles: keyStyle },
                        { content: averageValues["noDaysWithoutTherapy"], styles: valueStyle },
                        { content: "Standard Deviation of Peak Flow", styles: keyStyle },
                        { content: averageValues["sdExpFlow"], styles: valueStyle },
                    ],
                ];

                // Render product details table
                pdf.autoTable({
                    body: productDetails,
                    startY: 120,
                    columnStyles: {
                        0: { minCellWidth: 50 }, // Minimum width for column 1
                        1: { minCellWidth: 50 }, // Minimum width for column 2
                        2: { minCellWidth: 50 }, // Minimum width for column 3
                        3: { minCellWidth: 50 }, // Minimum width for column 4
                    },
                    styles: {
                        fontSize: 10,
                        halign: "center",
                        cellPadding: 6,
                        overflow: "linebreak",
                        lineWidth: 0.5,
                        lineColor: [200, 200, 200],
                    },
                    margin,
                });

                // Render stats details table
                pdf.autoTable({
                    body: statsDetails,
                    startY: pdf.previousAutoTable.finalY + 20,
                    columnStyles: {
                        0: { minCellWidth: 50 }, // Minimum width for column 1
                        1: { minCellWidth: 50 }, // Minimum width for column 2
                        2: { minCellWidth: 50 }, // Minimum width for column 3
                        3: { minCellWidth: 50 }, // Minimum width for column 4
                    },
                    styles: {
                        fontSize: 10,
                        halign: "center",
                        cellPadding: 6,
                        overflow: "linebreak",
                        lineWidth: 0.5,
                        lineColor: [200, 200, 200],
                    },
                    margin,
                });
            }


            addTableAndSummary(pdf);
            addFooter(pdf, 1);
            if (reportImages.length === 0) {
                if (plotContainerRef.current) {
                    try {
                        plotRefs.current.forEach(async (plot, index) => {
                            const canvas = await html2canvas(plot.current, { scale: 2 });
                            const imgData = canvas.toDataURL("image/png");
                            setReportImages([...reportImages, imgData]);
                            pdf.addPage();
                            addHeaderWithLogosAndText(pdf);
                            addWatermarkLogo(pdf);

                            pdf.setFontSize(14);
                            pdf.addImage(imgData, "PNG", margin, 120, 500, 500);

                            pdf.setFontSize(9);
                            addFooter(pdf, 0 + 2);
                        });
                        // pdf.addPage();
                        // addHeaderWithLogosAndText(pdf);
                        // pdf.text("Abbrevations and Units", pageWidth / 2, 120, {
                        //     align: "center",
                        // });
                        // addWatermarkLogo(pdf);
                        // addAbbrevationsUnits(pdf);
                        // addFooter(pdf, reportImages?.length + 2);
                        setUpdatingReport(false);
                        pdf.save(pdfName);
                        setLoadingAutoPdf(false);
                    } catch (err) {
                        console.error("Error capturing chart:", err);
                    }
                }
            } else {
                reportImages.forEach((imgData, index) => {
                    pdf.addPage();
                    addHeaderWithLogosAndText(pdf);
                    addWatermarkLogo(pdf);

                    pdf.setFontSize(14);
                    pdf.addImage(imgData, "PNG", margin, 120, 500, 500);

                    pdf.setFontSize(9);
                    const lines = pdf.splitTextToSize(
                        summaries[index],
                        pageWidth - margin * 2
                    );
                    // pdf.text(lines, margin, 650);

                    addFooter(pdf, index + 2);
                });
                // pdf.addPage();
                // addHeaderWithLogosAndText(pdf);
                // pdf.text("Abbrevations and Units", pageWidth / 2, 120, {
                //     align: "center",
                // });
                // addWatermarkLogo(pdf);
                // addAbbrevationsUnits(pdf);
                // addFooter(pdf, reportImages?.length + 2);

                pdf.save(pdfName);
                setLoadingAutoPdf(false);
            }
        };

        const handleImageClick = (img) => {
            setSelectedImage(img);
            setZoomLevel(1); // Reset zoom when opening a new image
        };

        const handleDeleteImage = (indexToDelete) => {
            setReportImages(
                reportImages.filter((_, index) => index !== indexToDelete)
            );
            setSummaries(summaries.filter((_, index) => index !== indexToDelete));
        };

        const handleZoomIn = () => {
            setZoomLevel((prev) => prev + 0.2);
        };

        const handleZoomOut = () => {
            setZoomLevel((prev) => Math.max(prev - 0.2, 1));
        };

        const handleCloseDialog = () => {
            setSelectedImage(null);
        };
        const mergeIndividualTherapyValues = (data, axis, start, end) => {
            return data
                ?.slice(0, data?.P_IP?.length)
                ?.map((entry) => entry[axis])
                ?.flat()
                ?.slice(start, end);
        };
        const generatePlotData = (data, start, end) => {
            return [
                {
                    x: data.Cycle_P_P[0]?.x?.slice(start, end),
                    y: data.Cycle_P_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Pause Pressure",
                    marker: {
                        color: "rgba(68, 187, 68, 0.8)",
                        line: {
                            color: "rgba(68, 187, 68, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                (data.Cycle_P_P[0]?.o?.slice(start, end) || []).map(
                                    (oValue) => (oValue === 1 || oValue === 2 ? "/" : "") // Pattern only for certain `o` values
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: (data.Cycle_P_P[0]?.o?.slice(start, end) || []).map(
                                (oValue) =>
                                    oValue === 1 || oValue === 2
                                        ? "rgba(0, 128, 0, 0.7)"
                                        : "rgba(68, 187, 68, 0.8)"
                            ),
                            bgcolor: "rgba(68, 187, 68, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cmH₂O ", // Custom hover text

                    yaxis: "y1",
                },
                {
                    x: data.Cycle_I_P[0]?.x?.slice(start, end),
                    y: data.Cycle_I_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Insp Pressure",
                    marker: {
                        color: "rgb(20, 97, 211, 0.8)",
                        line: {
                            color: "rgb(20, 97, 211, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                data.Cycle_I_P[0]?.o?.slice(start, end).map(
                                    (oValue) => (oValue === 1 || oValue === 2 ? "/" : "") // Pattern only for certain `o` values
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: data.Cycle_I_P[0]?.o
                                ?.slice(start, end)
                                .map((oValue) =>
                                    oValue === 1 || oValue === 2
                                        ? "rgba(20, 128, 211, 0.7)"
                                        : "rgba(20, 97, 211, 0.8)"
                                ),
                            bgcolor: "rgba(20, 97, 211, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cmH₂O ", // Custom hover text

                    yaxis: "y1",
                },
                {
                    x: data.Cycle_E_P[0]?.x?.slice(start, end),
                    y: data.Cycle_E_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Exp Pressure",
                    marker: {
                        color: "rgb(255, 174, 66,0.8)",
                        line: {
                            color: "rgb(255, 174, 66, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                data.Cycle_E_P[0]?.o?.slice(start, end).map(
                                    (oValue) => (oValue === 1 || oValue === 3 ? "/" : "") // Pattern only for certain `o` values
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: data.Cycle_E_P[0]?.o
                                ?.slice(start, end)
                                .map((oValue) =>
                                    oValue === 1 || oValue === 3
                                        ? "rgba(255, 128, 66, 0.7)"
                                        : "rgba(255, 174, 66, 0.8)"
                                ),
                            bgcolor: "rgba(255, 174, 66, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cmH₂O ", // Custom hover text

                    yaxis: "y1",
                },
                {
                    x: [0],
                    y: [0],
                    type: "bar",
                    name: "Oscillations Enabled",
                    marker: {
                        color: "#1461d3",
                        line: {
                            color: "#1461d3",
                            width: 1,
                        },
                        pattern: {
                            shape: "/",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: "#1461d3",
                            bgcolor: "r#1461d3",
                        },
                    },
                    yaxis: "y2",
                },
                {
                    x: [0],
                    y: [0],
                    type: "box",
                    name: "Oscillations Disabled",
                    marker: {
                        color: "#1461d3",
                        line: {
                            color: "#fff",
                            width: 1,
                        },
                        pattern: {
                            shape: "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: "#fff",
                            bgcolor: "#fff",
                        },
                    },
                    yaxis: "y2",
                },
                {
                    x: mergeIndividualTherapyValues(data.P_F, "x", start, end),
                    y: mergeIndividualTherapyValues(data.P_F, "y", start, end),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Peak Expiratory Flow (l/m)",
                    line: { color: "grey", dash: "dot" },
                    yaxis: "y2",
                    hovertemplate: "%{y} l/m ", // Custom hover text
                },
                {
                    x: mergeIndividualTherapyValues(data.P_IP, "x", start, end),
                    y: mergeIndividualTherapyValues(data.P_IP, "y", start, end),
                    type: "box",
                    mode: "lines",
                    name: "Peak Insp Pressure",
                    line: { color: "red", dash: "dot" },
                    yaxis: "y1",
                    // marker: {
                    //     color: 'rgba(255, 0, 0, 0.8)', // Marker color
                    // },
                    hovertemplate: "%{y} cmH20 ", // Custom hover text

                    // hoverinfo: 'y+name', // Show x, y values and the trace name on hover
                    // boxpoints: 'outliers', hoverinfo: 'x+y+name', // Display x, y values and name on hover
                    boxpoints: "all", // Show all points
                    // jitter: 1,           // Spread points horizontally for better visibility
                    pointpos: 0.1,
                },
                {
                    x: mergeIndividualTherapyValues(data.E_V, "x", start, end),
                    y: mergeIndividualTherapyValues(data.E_V, "y", start, end),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Exploratory Volume (ml)",
                    line: { color: "blue", dash: "dash" },
                    yaxis: "y3",
                    hovertemplate: "%{y} ml ", // Custom hover text
                },
            ];
        };
        let rangeCount = 0;
        const generateRanges = (data, max = 5000) => {
            rangeCount = Math.ceil(data.Cycle_P_P[0]?.x?.length / max);
            let chunks = [];
            for (let i = 0; i < rangeCount; i++) {
                const start = i * 300;
                const end = Math.min((i + 1) * 300, data.Cycle_P_P[0]?.x?.length);
                chunks.push(generatePlotData(data, start, end));
            }
            return chunks;
        };
        function calculateCategoryTickvals(categories, maxTicks) {
            const step = Math.ceil(categories.length / maxTicks);
            return categories.filter((_, index) => index % step === 0);
        }

        const xValues = data?.P_F?.flatMap((item) => item.x);
        const maxTicks = 10; // Define the maximum number of visible ticks
        const tickvals = calculateCategoryTickvals(xValues, maxTicks);

        function calculateDynamicDtick(minY, maxY, desiredTicks = 100) {
            const range = maxY - minY;
            if (range <= 0) return 1;
            const rawInterval = range / desiredTicks;
            const magnitude = Math.pow(10, Math.floor(Math.log10(rawInterval)));
            const niceInterval = Math.ceil(rawInterval / magnitude) * magnitude;
            return niceInterval;
        }

        const yValues = data.E_V.flatMap((item) => item.y) ?? [];
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);
        const dynamicDtick = calculateDynamicDtick(minY, maxY);
        const layout = {
            width: 1300,
            xaxis: {
                showgrid: true,
                tickvals: tickvals,
                type: "category",
            },
            yaxis: {
                title: "Pressure (cmH₂O)",
                side: "left",
                position: 0,
                autorange: false,
                range: [-90, 90]
            },
            yaxis2: {
                title: "Peak Expiratory Flow (l/m)",
                overlaying: "y",
                side: "right",
                position: 1,
                autorange: false,
                range: [90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) + 100, -90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) - 100], // Scale to match yaxis
                showgrid: false
            },
            yaxis3: {
                title: "Expiratory volume (ml)",
                side: "left",
                anchor: "free",
                domain: [0, 0.45],
                position: 0,
                tickformat: ".f",
                range: [minY, maxY + 5000]
            },
            grid: {
                rows: 2,
                columns: 1,
                pattern: "independent",
                roworder: "top to bottom",
                rowgap: 0.1,
            },
            height: 1000,
            showlegend: true,
            legend: {
                orientation: "h",
                x: 1,
                y: 1,
                xanchor: "right",
                yanchor: "bottom",
                traceorder: "normal",
            },
        };
        const plotRefs = useRef([]);

        return (
            <div>
                <div className="">
                    <div className=" overflow-y-auto " ref={plotContainerRef}>
                        {/* {generateRanges(data)?.length > 0 && <h3 className="text-center font-bold m-2 text-lg">Graphs are Split to {generateRanges(data).length} Individual Graphs</h3>} */}
                        {data &&
                            data.P_F &&
                            data.P_F[0] &&
                            generateRanges(data).map((chunk, index) => {
                                if (!plotRefs.current[index]) {
                                    plotRefs.current[index] = React.createRef();
                                }
                                return (
                                    <div
                                        className="overflow-y-auto plot-item  h-96 p-2 shadow-lg bg-gray-100 rounded-md"
                                        key={index}
                                        ref={plotRefs.current[index]}
                                    >

                                        <Plot
                                            data={chunk}
                                            layout={layout}
                                            config={{ responsive: true }}
                                            onInitialized={handleGraphLoading}
                                        />

                                    </div>
                                );
                            })}
                    </div>
                </div>

                <Drawer
                    anchor="right"
                    open={hidePreview}
                    onClose={() => setHidePreview(false)} // Close the drawer when clicking outside
                >
                    <Box sx={{ width: "400px", padding: "20px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <Typography variant="h6" style={{ alignItems: "center" }}>Generate Report</Typography>
                            <CloseOutlined
                                onClick={() => setHidePreview(false)}
                                sx={{ cursor: "pointer", color: "#333" }}
                            />
                        </div>
                        <div
                            style={{
                                backgroundColor: "#f9f9f9",
                                padding: "16px",
                                borderRadius: "12px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                overflowY: "auto",
                                transition: "all 0.3s ease",
                            }}
                        >
                            {/* Button Group */}
                            <div
                                className="button-group"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10
                                }}
                            >
                                <div className="graph-instructions">
                                    <h3>Add Graph to Your Report</h3>
                                    <p>
                                        You can customize and add the current graph to your report:
                                    </p>
                                    <ul>
                                        <li>Hover over the graph to resize, zoom, or crop.</li>
                                        <li>
                                            Use filters like <strong>date range</strong> or{" "}
                                            <strong>therapy</strong> to refine the view.
                                        </li>
                                        <li>
                                            Once ready, click <strong>Add Graph to Report</strong> to
                                            include it in your report.
                                        </li>
                                    </ul>
                                </div>
                                <Button
                                    className="custom-button"
                                    variant="contained"
                                    onClick={downloadReport}
                                    startIcon={
                                        <Download
                                            sx={{
                                                fontSize: "20px",
                                                transition: "transform 0.3s ease",
                                            }}
                                        />
                                    }
                                    sx={{
                                        backgroundColor: "#2196F3",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        height: "50px",
                                        borderRadius: "10px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                        transition:
                                            "transform 0.2s ease, background-color 0.3s ease",
                                        "&:hover": {
                                            backgroundColor: "#1E88E5",
                                            transform: "scale(1.03)",
                                        },
                                    }}
                                >
                                    Download
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "8px",
                                    margin: "10px",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="div"
                                    style={{ color: "#333", fontWeight: "bold", flexGrow: 1 }}
                                >
                                    Selected Graphs: {reportImages.length}
                                </Typography>
                            </div>
                            <div className="report-list">
                                <div
                                    style={{
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        padding: "10px",
                                    }}
                                >
                                    {reportImages.map((img, index) => (
                                        <div className="report-item" key={index} style={{ marginBottom: "10px" }}>
                                            <img src={img} alt={`Report ${index}`} />
                                            <div className="image-overlay">
                                                <IconButton
                                                    onClick={() => handleImageClick(img)}
                                                    sx={{
                                                        color: "#ffffff",
                                                        transition: "transform 0.3s ease",
                                                        "&:hover": { transform: "scale(1.2)" },
                                                    }}
                                                >
                                                    <ZoomIn sx={{ fontSize: "20px" }} />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => handleDeleteImage(index)}
                                                    sx={{
                                                        color: "#FF5252",
                                                        transition: "transform 0.3s ease",
                                                        "&:hover": { transform: "scale(1.2)" },
                                                    }}
                                                >
                                                    <Delete sx={{ fontSize: "20px" }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {reportImages.length < 1 && (
                                    <p
                                        style={{
                                            fontSize: "14px",
                                            color: "#666666",
                                            textAlign: "center",
                                        }}
                                    >
                                        Click on 'Add Graph to Report' to copy the graphs and add
                                        them to the report for download.
                                    </p>
                                )}
                            </div>
                        </div>
                    </Box>
                </Drawer>

                {/* Dialog for image viewing */}
                <Dialog
                    open={!!selectedImage}
                    onClose={handleCloseDialog}
                    maxWidth="md"
                >
                    <DialogTitle>Image Viewer</DialogTitle>
                    <DialogContent>
                        <div className="image-viewer">
                            <img
                                src={selectedImage}
                                alt="Selected report"
                                style={{ transform: `scale(${zoomLevel})` }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleZoomIn} variant="contained">
                            Zoom In
                        </Button>
                        <Button onClick={handleZoomOut} variant="contained">
                            Zoom Out
                        </Button>
                        <Button
                            onClick={handleCloseDialog}
                            variant="contained"
                            color="secondary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
);

export default MultiSubplotsStacked;
