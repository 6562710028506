import React, {
    forwardRef,
    useRef,
    useState,
    useImperativeHandle,
} from "react";
import Plot from "react-plotly.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import "./styles.css";
import logoL from "./arc.png";
import { Box } from "@mui/material";
import { Drawer, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { formatDate,getYearFromDate } from '../../../utils'
import {
    Download,
    ZoomIn,
    Delete,
} from "@mui/icons-material";
const MultiSubplotsStacked = forwardRef(
    (
        { totalTherpies,
            setLoadingAutoPdf,
            setHidePreview,
            data,
            hidePreview,
            startDate,
            endDate,
            averageValues,
            serialNumber,
            handleGraphLoading,
            generatedBy,
            setGeneratedBy
        },
        ref
    ) => {
        // const [rangeCount, setRange] = useState(0)
        const plotContainerRef = useRef(null);
        const [reportImages, setReportImages] = useState([]);
        const [summaries, setSummaries] = useState([]);
        const [updatingReport, setUpdatingReport] = useState(false);
        const [selectedImage, setSelectedImage] = useState(null);
        const [zoomLevel, setZoomLevel] = useState(1);

        let pdfName;
        const handleParentReport = () => {
            if (reportImages.length > 0) {
                setHidePreview(true);
                setLoadingAutoPdf(false);

            } else {
                addToReport();
                setLoadingAutoPdf(false);
            }
        };
        const addToReport = async () => {
            setUpdatingReport(true);
            if (plotContainerRef.current) {
                await html2canvas(plotContainerRef.current, { scale: 2 })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/png");
                        setReportImages([...reportImages, imgData]);
                        setSummaries([...summaries, `Fig: Setttings vs Peak Pressure and Peak Flow (${generatedBy !== ''? generatedBy: 'Default View'})`])
                        setTimeout(() => {
                            setUpdatingReport(false);
                        }, 10);
                        if (reportImages.length > 0) setHidePreview(true);
                        setLoadingAutoPdf(false);
                    })
                    .catch((err) => {
                        console.error("Error capturing chart:", err);
                    });
            }
        };
        // Expose the function to the parent via the ref
        useImperativeHandle(ref, () => ({
            callChildFunction: downloadReport,
            addToReport,
            handleParentReport,
        }));

        const downloadReport = async () => {
            setLoadingAutoPdf(true);
            const pdf = new jsPDF("p", "pt", "a4");
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const margin = 60;
            function addHeaderWithLogosAndText(pdf) {
                const logoWidth = 35 * 2; // Double the width
                const logoHeight = 35 * 1.8; // 1.8 times the height
                pdf.addImage(logoL, "PNG", margin, 20, logoWidth, logoHeight);
                pdf.setFontSize(14);
                const titleX = pageWidth / 2 - 40;
                const titleY = 40;
                pdf.text("BiWaze", titleX - 40, titleY, { align: "center" });
                pdf.setFontSize(10);
                pdf.text("®", titleX - 15, titleY - 5);
                pdf.setFontSize(14);
                pdf.text("Cough Compliance Report", titleX + 80, titleY, { align: "center" });
                pdf.setFontSize(12);
            }
            function addFooter(pdf, pageNumber) {
                pdf.setFontSize(10);
                pdf.text(
                    "Confidential Report",
                    margin,
                    pageHeight - 20
                );
                pdf.text(`Page: ${pageNumber}`, pageWidth - margin, pageHeight - 20, {
                    align: "right",
                });
            }
            function addDateRange(pdf, startDateTime, endDateTime) {
                const options = { year: "numeric", month: "long", day: "numeric" };

                pdf.setFontSize(10);
                pdfName = `BiWaze®Cough_${serialNumber}_TherapyReport_${formatDate(data.Cycle_P_P[0]?.x[0])}-${formatDate(data.Cycle_P_P[0]?.x[data.Cycle_P_P[0]?.x.length - 1])}.pdf`;
                const reportGeneratedText = `Report Generated on: ${new Date().toLocaleDateString("en-US", options)}`;
                const pageWidth = pdf.internal.pageSize.getWidth();
                const marginRight = 40;
                const textWidth = pdf.getTextWidth(reportGeneratedText);
                const xPosition = pageWidth - textWidth - marginRight;
                pdf.text(reportGeneratedText, xPosition, 65);

            }
            function addTableAndSummary(pdf) {
                addHeaderWithLogosAndText(pdf);
                addDateRange(pdf, startDate, endDate);

                const keyStyle = {
                    fillColor: "#e8e8e8",
                    textColor: 0,
                    fontSize: 10,
                    fontStyle: "normal",
                    halign: "left",
                };

                const valueStyle = {
                    fillColor: "#fff",
                    textColor: 0,
                    fontSize: 10,
                    halign: "center",
                };

                const productDetails = [
                    [
                        { content: "Product", styles: keyStyle },
                        { content: "Biwaze® Cough", styles: valueStyle },
                        { content: "Serial Number", styles: keyStyle },
                        { content: serialNumber, styles: valueStyle },
                        { content: `HMR (H:M:S) As of ${formatDate(endDate)}`, styles: keyStyle },
                        { content: averageValues["HMR"], styles: valueStyle },
                    ],

                ];

                const statsDetails = [
                    [
                        { content: "Average therapy cycles per session", styles: keyStyle },
                        { content: averageValues["average_NC"], styles: valueStyle },
                        { content: "Average Expiratory Volume (ml)", styles: keyStyle },
                        { content: averageValues["average_EV"], styles: valueStyle },
                    ],
                    [
                        { content: "Average therapy sessions per day", styles: keyStyle },
                        { content: averageValues["average_Therapy"], styles: valueStyle },
                        { content: "Standard Deviation of Expired Volume (ml)", styles: keyStyle },
                        { content: averageValues["sdExpVol"], styles: valueStyle },
                    ],
                    [
                        { content: "Total Therapy Sessions", styles: keyStyle },
                        { content: totalTherpies, styles: valueStyle },
                        { content: "Average Peak Cough Flow (lpm)", styles: keyStyle },
                        { content: averageValues["average_PF"], styles: valueStyle },
                    ],
                    [
                        { content: "Number of days with no therapy", styles: keyStyle },
                        { content: averageValues["noDaysWithoutTherapy"], styles: valueStyle },
                        { content: "Standard Deviation of Peak Flow (lpm)", styles: keyStyle },
                        { content: averageValues["sdExpFlow"], styles: valueStyle },
                    ],
                    [
                        // { content: ` Device HMR (H:M:S) As of ${endDate}`, styles: keyStyle },
                        // { content: averageValues["HMR"], styles: valueStyle },
                        { content: ` Net Device Usage (H:M:S)`, styles: keyStyle }, // Placeholder for alignment
                        { content: averageValues["usageHMR"], styles: valueStyle },
                    ]
                ];

                // Render product details table
                // Product Details Table
                pdf.autoTable({
                    body: productDetails,
                    startY: 83,
                    columnStyles: {
                        0: { minCellWidth: 80 }, // Adjusted column width for better alignment
                        1: { minCellWidth: 50 },
                        2: { minCellWidth: 80 },
                        3: { minCellWidth: 50 },
                    },
                    styles: {
                        fontSize: 10,
                        halign: "center",
                        cellPadding: 6,
                        overflow: "linebreak",
                        lineWidth: 0.5,
                        lineColor: [200, 200, 200],
                    },
                    margin: { ...margin, bottom: 15, top: 0 },
                });
             
                
                // Extract years from startDate and endDate
                const startYear = getYearFromDate(startDate);
                const endYear = getYearFromDate(endDate);
                if (
                    isNaN(startYear) ||
                    isNaN(endYear) ||
                    startYear < 2020 || 
                    endYear > new Date().getFullYear() + 1 // Check if endYear is more than one year in the future
                ) {                    
                    const warnings = [
                        "We found dates on your BiWaze® system that may be incorrect.",
                        "Please verify and update the system date to ensure accurate therapy dates moving forward."
                    ];
                    
                    pdf.setFontSize(7);
                    const pageWidth = pdf.internal.pageSize.getWidth();
                                        warnings.forEach((warning, index) => {
                        const textWidth = pdf.getTextWidth(warning);
                        const textX = (pageWidth - textWidth) / 2; // Center the text
                        const textY = pdf.previousAutoTable.finalY + 10 + index * 10; // Dynamic vertical position
                        pdf.text(warning, textX, textY);
                    });
                    
                }
              
                pdf.autoTable({
                    head: [[`Usage From ${startDate} To ${endDate}`]],
                    headStyles: {
                        fillColor: [220, 220, 220], // Light grey background
                        textColor: [0, 0, 0], // Black text
                        halign: "center", // Center align
                        fontSize: 10,
                        fontStyle: "normal",
                    },
                    body: [],
                    margin: { ...margin, bottom: 0, top: 10 },
                });
                pdf.autoTable({
                    body: statsDetails,
                    startY: pdf.previousAutoTable.finalY + 5, // Add space below the header
                    columnStyles: {
                        0: { minCellWidth: 80 }, // Consistent width with Product Details
                        1: { minCellWidth: 50 },
                        2: { minCellWidth: 80 },
                        3: { minCellWidth: 50 },
                        4: { minCellWidth: 80 },
                    },
                    styles: {
                        fontSize: 10,
                        halign: "center",
                        cellPadding: 5,
                        lineWidth: 0.5,
                        lineColor: [200, 200, 200],
                    },
                    margin: { ...margin, top: 0 },
                });
            }
            function addFirstImage(pdf) {
                reportImages.slice(0, 1).forEach((imgData, index) => {
                    pdf.setFontSize(10);

                    const pageWidth = pdf.internal.pageSize.getWidth();

                    // Add the summary text and calculate its Y position dynamically
                    const summaryText = summaries[index];
                    const summaryTextWidth = pdf.getTextWidth(summaryText);
                    const summaryTextX = (pageWidth - summaryTextWidth) / 2; // Center the text horizontally
                    const textHeight = 10; // Approximate height of the text line (adjust based on font size)
                    const summaryTextY = 300; // Starting Y position for the text
                    pdf.text(summaryText, summaryTextX, summaryTextY);

                    // Dynamically calculate the image Y position below the text
                    const imageY = summaryTextY + 5; // Add some padding after the text
                    const imageX = margin;
                    const imageWidth = 500;
                    const imageHeight = 500;
                    pdf.addImage(imgData, "PNG", imageX, imageY, imageWidth, imageHeight);

                    // Dynamically calculate the center of the image for the centered text
                    const imageCenterY = imageY + 25 + imageHeight / 2;
                    const centerText = "Fig: Exhaled volumes";
                    const centerTextWidth = pdf.getTextWidth(centerText);
                    const centerTextX = (pageWidth - centerTextWidth) / 2; // Center horizontally
                    pdf.setFontSize(10);
                    pdf.text(centerText, centerTextX, imageCenterY); // Add centered text inside the image
                });

            }
            addTableAndSummary(pdf);
            addFirstImage(pdf)
            addFooter(pdf, 1);
            if (reportImages.length === 0) {
                if (plotContainerRef.current) {
                    try {
                        plotRefs.current.forEach(async (plot, index) => {
                            const canvas = await html2canvas(plot.current, { scale: 2 });
                            const imgData = canvas.toDataURL("image/png");
                            setReportImages([...reportImages, imgData]);
                            pdf.addPage();
                            addHeaderWithLogosAndText(pdf);

                            pdf.setFontSize(14);
                            pdf.addImage(imgData, "PNG", margin, 120, 500, 500);

                            pdf.setFontSize(9);
                            addFooter(pdf, 0 + 2);
                        });
                        // pdf.addPage();
                        // addHeaderWithLogosAndText(pdf);
                        // pdf.text("Abbrevations and Units", pageWidth / 2, 120, {
                        //     align: "center",
                        // });
                        // addWatermarkLogo(pdf);
                        // addAbbrevationsUnits(pdf);
                        // addFooter(pdf, reportImages?.length + 2);
                        setUpdatingReport(false);
                        pdf.save(pdfName);
                        setLoadingAutoPdf(false);
                    } catch (err) {
                        console.error("Error capturing chart:", err);
                    }
                }
            } else {
                reportImages.slice(1).forEach((imgData, index) => {
                    pdf.addPage();
                    addHeaderWithLogosAndText(pdf);
                    pdf.setFontSize(10);
                    const pageWidth = pdf.internal.pageSize.getWidth();
                    const textWidth = pdf.getTextWidth(summaries[index]);
                    const xPosition = (pageWidth - textWidth) / 2;
                    pdf.text(summaries[index], xPosition, 110);
                    const imageX = margin;
                    const imageY = 130;
                    const imageWidth = 500;
                    const imageHeight = 500;
                    pdf.addImage(imgData, "PNG", imageX, imageY, imageWidth, imageHeight);
                    const imageCenterY = imageY + 5 + imageHeight / 2;
                    const centerText = "Fig: Expiratory volumes";
                    const centerTextWidth = pdf.getTextWidth(centerText);
                    const centerTextX = (pageWidth - centerTextWidth) / 2;
                    pdf.setFontSize(10);
                    pdf.text(centerText, centerTextX, imageCenterY);
                    pdf.setFontSize(9);
                    addFooter(pdf, index + 2);
                });

                pdf.save(pdfName);
                setLoadingAutoPdf(false);
            }
        };

        const handleImageClick = (img) => {
            setSelectedImage(img);
            setZoomLevel(1); // Reset zoom when opening a new image
        };

        const handleDeleteImage = (indexToDelete) => {
            setReportImages(
                reportImages.filter((_, index) => index !== indexToDelete)
            );
            setSummaries(summaries.filter((_, index) => index !== indexToDelete));
        };

        const handleZoomIn = () => {
            setZoomLevel((prev) => prev + 0.2);
        };

        const handleZoomOut = () => {
            setZoomLevel((prev) => Math.max(prev - 0.2, 1));
        };

        const handleCloseDialog = () => {
            setSelectedImage(null);
        };
        const mergeIndividualTherapyValues = (data, axis, start, end) => {
            return data
                ?.slice(0, data?.P_IP?.length)
                ?.map((entry) => entry[axis])
                ?.flat()
                ?.slice(start, end);
        };
        const generatePlotData = (data, start, end) => {
            return [
                {
                    x: data.Cycle_P_P[0]?.x?.slice(start, end),
                    y: data.Cycle_P_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Pause Pressure",
                    marker: {
                        color: "rgba(68, 187, 68, 0.8)",
                        line: {
                            color: "rgba(68, 187, 68, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                (data.Cycle_P_P[0]?.o?.slice(start, end) || []).map(
                                    (oValue) => (oValue === 1 || oValue === 2 ? "/" : "") // Pattern only for certain `o` values
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: (data.Cycle_P_P[0]?.o?.slice(start, end) || []).map(
                                (oValue) =>
                                    oValue === 1 || oValue === 2
                                        ? "rgba(0, 128, 0, 0.7)"
                                        : "rgba(68, 187, 68, 0.8)"
                            ),
                            bgcolor: "rgba(68, 187, 68, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cmH₂O ", // Custom hover text

                    yaxis: "y1",
                },
                {
                    x: data.Cycle_I_P[0]?.x?.slice(start, end),
                    y: data.Cycle_I_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Insp Pressure",
                    marker: {
                        color: "rgb(20, 97, 211, 0.8)",
                        line: {
                            color: "rgb(20, 97, 211, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                data.Cycle_I_P[0]?.o?.slice(start, end).map(
                                    (oValue) => (oValue === 1 || oValue === 2 ? "/" : "") // Pattern only for certain `o` values
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: data.Cycle_I_P[0]?.o
                                ?.slice(start, end)
                                .map((oValue) =>
                                    oValue === 1 || oValue === 2
                                        ? "rgba(20, 128, 211, 0.7)"
                                        : "rgba(20, 97, 211, 0.8)"
                                ),
                            bgcolor: "rgba(20, 97, 211, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cmH₂O ", // Custom hover text

                    yaxis: "y1",
                },
                {
                    x: data.Cycle_E_P[0]?.x?.slice(start, end),
                    y: data.Cycle_E_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Exp Pressure",
                    marker: {
                        color: "rgb(255, 174, 66,0.8)",
                        line: {
                            color: "rgb(255, 174, 66, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                data.Cycle_E_P[0]?.o?.slice(start, end).map(
                                    (oValue) => (oValue === 1 || oValue === 3 ? "/" : "") // Pattern only for certain `o` values
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: data.Cycle_E_P[0]?.o
                                ?.slice(start, end)
                                .map((oValue) =>
                                    oValue === 1 || oValue === 3
                                        ? "rgba(255, 128, 66, 0.7)"
                                        : "rgba(255, 174, 66, 0.8)"
                                ),
                            bgcolor: "rgba(255, 174, 66, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cmH₂O ", // Custom hover text

                    yaxis: "y1",
                },
                {
                    x: [0],
                    y: [0],
                    type: "bar",
                    name: "Oscillations Enabled",
                    marker: {
                        color: "#1461d3",
                        line: {
                            color: "#1461d3",
                            width: 1,
                        },
                        pattern: {
                            shape: "/",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: "#1461d3",
                            bgcolor: "r#1461d3",
                        },
                    },
                    yaxis: "y2",
                },
                {
                    x: [0],
                    y: [0],
                    type: "box",
                    name: "Oscillations Disabled",
                    marker: {
                        color: "#1461d3",
                        line: {
                            color: "#fff",
                            width: 1,
                        },
                        pattern: {
                            shape: "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: "#fff",
                            bgcolor: "#fff",
                        },
                    },
                    yaxis: "y2",
                },
                {
                    x: mergeIndividualTherapyValues(data.P_F, "x", start, end),
                    y: mergeIndividualTherapyValues(data.P_F, "y", start, end),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Peak Expiratory Flow (l/m)",
                    line: { color: "grey", dash: "dot" },
                    yaxis: "y2",
                    hovertemplate: "%{y} l/m ", // Custom hover text
                },
                {
                    x: mergeIndividualTherapyValues(data.P_IP, "x", start, end),
                    y: mergeIndividualTherapyValues(data.P_IP, "y", start, end),
                    type: "box",
                    mode: "lines",
                    name: "Peak Insp Pressure",
                    line: { color: "red", dash: "dot" },
                    yaxis: "y1",
                    // marker: {
                    //     color: 'rgba(255, 0, 0, 0.8)', // Marker color
                    // },
                    hovertemplate: "%{y} cmH20 ", // Custom hover text

                    // hoverinfo: 'y+name', // Show x, y values and the trace name on hover
                    // boxpoints: 'outliers', hoverinfo: 'x+y+name', // Display x, y values and name on hover
                    boxpoints: "all", // Show all points
                    // jitter: 1,           // Spread points horizontally for better visibility
                    pointpos: 0.1,
                },
                {
                    x: mergeIndividualTherapyValues(data.E_V, "x", start, end),
                    y: mergeIndividualTherapyValues(data.E_V, "y", start, end),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Exploratory Volume (ml)",
                    line: { color: "blue", dash: "dash" },
                    yaxis: "y3",
                    hovertemplate: "%{y} ml ", // Custom hover text
                },
            ];
        };
        let rangeCount = 0;
        const generateRanges = (data, max = 5000) => {
            rangeCount = Math.ceil(data.Cycle_P_P[0]?.x?.length / max);
            let chunks = [];
            for (let i = 0; i < rangeCount; i++) {
                const start = i * 300;
                const end = Math.min((i + 1) * 300, data.Cycle_P_P[0]?.x?.length);
                chunks.push(generatePlotData(data, start, end));
            }
            return chunks;
        };
        function calculateCategoryTickvals(categories, maxTicks) {
            const step = Math.ceil(categories.length / maxTicks);
            return categories.filter((_, index) => index % step === 0);
        }

        const xValues = data?.P_F?.flatMap((item) => item.x);
        const maxTicks = 10; // Define the maximum number of visible ticks
        const tickvals = calculateCategoryTickvals(xValues, maxTicks);

        function calculateDynamicDtick(minY, maxY, desiredTicks = 100) {
            const range = maxY - minY;
            if (range <= 0) return 1;
            const rawInterval = range / desiredTicks;
            const magnitude = Math.pow(10, Math.floor(Math.log10(rawInterval)));
            const niceInterval = Math.ceil(rawInterval / magnitude) * magnitude;
            return niceInterval;
        }

        const yValues = data.E_V.flatMap((item) => item.y) ?? [];
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);
        const dynamicDtick = calculateDynamicDtick(minY, maxY);
        const layout = {
            width: 1300,
            xaxis: {
                showgrid: true,
                tickvals: tickvals,
                type: "category",
                ticktext: tickvals < 15 ? tickvals :tickvals.map(dateTime => {
                    const date = new Date(dateTime);
                    return date.toISOString().split('T')[0]; // Format to show only date
                }),
            },
            yaxis: {
                title: "Pressure (cmH₂O)",
                side: "left",
                position: 0,
                autorange: false,
                range: [-90, 90],
                fixedrange: true,
            },
            yaxis2: {
                title: "Peak Expiratory Flow (l/m)",
                overlaying: "y",
                side: "right",
                position: 1,
                autorange: false,
                range: [90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) + 100, -90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) - 100], // Scale to match yaxis
                showgrid: false,
                fixedrange: true,
            },
            yaxis3: {
                title: "Expiratory volume (ml)",
                side: "left",
                anchor: "free",
                domain: [0, 0.45],
                position: 0,
                tickformat: ".f",
                range: [minY, maxY + 5000],
                fixedrange: true,
            },
            grid: {
                rows: 2,
                columns: 1,
                pattern: "independent",
                roworder: "top to bottom",
                rowgap: 0.1,
            },
            height: 1000,
            showlegend: true,
            legend: {
                orientation: "h",
                x: 1,
                y: 1,
                xanchor: "right",
                yanchor: "bottom",
                traceorder: "normal",
            },

            dragmode: "zoom",
            selectionmode: "rectangle",
            selectdirection: "h"
        };
        const handleRelayout = (event) => {
            setGeneratedBy("Zoomed")
            if (event["xaxis.range[0]"] || event["xaxis.range[1]"]) {
                console.log("Zoom event detected!");
                console.log("New X-axis range:", event["xaxis.range[0]"], event["xaxis.range[1]"]);
            }

            if (event["yaxis.range[0]"] || event["yaxis.range[1]"]) {
                console.log("New Y-axis range:", event["yaxis.range[0]"], event["yaxis.range[1]"]);
            }
        };
        const plotRefs = useRef([]);
        const getLayout = (chunk, index) => {
            console.log(chunk)
            const containerWidth = window.innerWidth;
            const containerHeight = window.innerHeight;
            return {
                // width: containerWidth * 0.85, // Use 95% of the container width
                // height: containerHeight * 1.3,
                width: 1300,
                height:1000,
                grid: {
                    rows: 2,
                    columns: 1,
                    pattern: "independent",
                    roworder: "top to bottom",
                    rowgap: 0.1,
                },
                xaxis: {
                    showgrid: true,
                    tickvals: chunk[0].x, // Dynamically adjust ticks based on chunk
                    type: "category",
                    ticktext: chunk[0].x.length < 15 ? chunk[0].x : chunk[0].x.map(dateTime => {
                        const date = new Date(dateTime);
                        return date.toISOString().split('T')[0]; // Format to show only date
                    }),
                },
 
                yaxis: {
                    title: "Pressure (cmH₂O)",
                    side: "left",
                    position: 0,
                    autorange: false,
                    range: [-90, 90],
                    fixedrange: true,
                },
                yaxis2: {
                    title: "Peak Expiratory Flow (l/m)",
                    overlaying: "y",
                    side: "right",
                    position: 1,
                    autorange: false,
                    range: [90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) + 100, -90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) - 100], // Scale to match yaxis
                    showgrid: false,
                    fixedrange: true,
                },
                yaxis3: {
                    title: "Expiratory Volume (ml)",
                    side: "left",
                    anchor: "free",
                    domain: [0, 0.45],
                    position: 0,
                    tickformat: ".f",
                    range: [
                        Math.min(...chunk.map((item) => item.y)) - 5000,
                        Math.max(...chunk.map((item) => item.y)) + 5000,
                    ],
                    fixedrange: true,
                },
                xaxis2: {
                    showgrid: true,
                    tickvals: chunk[0].x,
                    type: "category",
                    ticktext: chunk[0].x.length < 15 ? chunk[0].x : chunk[0].x.map((dateTime) => {
                      const date = new Date(dateTime);
                      return date.toISOString().split("T")[0]; // Format to show only date
                    }),
                    gridwidth: 1,
                    position: 0,
                    anchor: "y3",
                    overlaying: "x",
                  },
                showlegend: true,
                legend: {
                    orientation: "h",
                    x: 1,
                    y: 1,
                    xanchor: "right",
                    yanchor: "bottom",
                    traceorder: "normal",
                },
                dragmode: "zoom",
                selectionmode: "rectangle",
                selectdirection: "h",
            }
        }

        return (
            <div>
                <div className="">
                    <div className=" overflow-y-auto " ref={plotContainerRef}>
                        {/* {generateRanges(data)?.length > 0 && <h3 className="text-center font-bold m-2 text-lg">Graphs are Split to {generateRanges(data).length} Individual Graphs</h3>} */}
                        {data &&
                            data.P_F &&
                            data.P_F[0] &&
                            generateRanges(data).map((chunk, index) => {
                                if (!plotRefs.current[index]) {
                                    plotRefs.current[index] = React.createRef();
                                }
                                return (
                                    <div
                                        className="overflow-y-auto plot-item  h-96 p-2 shadow-lg bg-gray-100 rounded-md"
                                        key={index}
                                        ref={plotRefs.current[index]}
                                    >

                                        <Plot
                                            data={chunk}
                                            layout={layout} // Dynamic layout for each chunk
                                            config={{ responsive: true }}
                                            onInitialized={handleGraphLoading}
                                            onRelayout={handleRelayout} // Attach the zoom handler here

                                        />

                                    </div>
                                );
                            })}
                    </div>
                </div>

                <Drawer
                    anchor="right"
                    open={hidePreview}
                    onClose={() => setHidePreview(false)} // Close the drawer when clicking outside
                >
                    <Box sx={{ width: "400px", padding: "20px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <Typography variant="h6" style={{ alignItems: "center" }}>Generate Report</Typography>
                            <CloseOutlined
                                onClick={() => setHidePreview(false)}
                                sx={{ cursor: "pointer", color: "#333" }}
                            />
                        </div>
                        <div
                            style={{
                                backgroundColor: "#f9f9f9",
                                padding: "16px",
                                borderRadius: "12px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                overflowY: "auto",
                                transition: "all 0.3s ease",
                            }}
                        >
                            {/* Button Group */}
                            <div
                                className="button-group"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10
                                }}
                            >
                                <div className="graph-instructions">
                                    <h3>Add Graph to Your Report</h3>
                                    <p>
                                        You can customize and add the current graph to your report:
                                    </p>
                                    <ul>
                                        <li>Hover over the graph to resize, zoom, or crop.</li>
                                        <li>
                                            Use filters like <strong>date range</strong> or{" "}
                                            <strong>therapy</strong> to refine the view.
                                        </li>
                                        <li>
                                            Once ready, click <strong>Add Graph to Report</strong> to
                                            include it in your report.
                                        </li>
                                    </ul>
                                </div>
                                <Button
                                    className="custom-button"
                                    variant="contained"
                                    onClick={downloadReport}
                                    startIcon={
                                        <Download
                                            sx={{
                                                fontSize: "20px",
                                                transition: "transform 0.3s ease",
                                            }}
                                        />
                                    }
                                    sx={{
                                        backgroundColor: "#2196F3",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        height: "50px",
                                        borderRadius: "10px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                        transition:
                                            "transform 0.2s ease, background-color 0.3s ease",
                                        "&:hover": {
                                            backgroundColor: "#1E88E5",
                                            transform: "scale(1.03)",
                                        },
                                    }}
                                >
                                    Download
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "8px",
                                    margin: "10px",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="div"
                                    style={{ color: "#333", fontWeight: "bold", flexGrow: 1 }}
                                >
                                    Selected Graphs: {reportImages.length}
                                </Typography>
                            </div>
                            <div className="report-list">
                                <div
                                    style={{
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        padding: "10px",
                                    }}
                                >
                                    {reportImages.map((img, index) => (
                                        <div className="report-item" key={index} style={{ marginBottom: "10px" }}>
                                            <img src={img} alt={`Report ${index}`} />
                                            <div className="image-overlay">
                                                <IconButton
                                                    onClick={() => handleImageClick(img)}
                                                    sx={{
                                                        color: "#ffffff",
                                                        transition: "transform 0.3s ease",
                                                        "&:hover": { transform: "scale(1.2)" },
                                                    }}
                                                >
                                                    <ZoomIn sx={{ fontSize: "20px" }} />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => handleDeleteImage(index)}
                                                    sx={{
                                                        color: "#FF5252",
                                                        transition: "transform 0.3s ease",
                                                        "&:hover": { transform: "scale(1.2)" },
                                                    }}
                                                >
                                                    <Delete sx={{ fontSize: "20px" }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {reportImages.length < 1 && (
                                    <p
                                        style={{
                                            fontSize: "14px",
                                            color: "#666666",
                                            textAlign: "center",
                                        }}
                                    >
                                        Click on 'Add Graph to Report' to copy the graphs and add
                                        them to the report for download.
                                    </p>
                                )}
                            </div>
                        </div>
                    </Box>
                </Drawer>

                {/* Dialog for image viewing */}
                <Dialog
                    open={!!selectedImage}
                    onClose={handleCloseDialog}
                    maxWidth="md"
                >
                    <DialogTitle>Image Viewer</DialogTitle>
                    <DialogContent>
                        <div className="image-viewer">
                            <img
                                src={selectedImage}
                                alt="Selected report"
                                style={{ transform: `scale(${zoomLevel})` }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleZoomIn} variant="contained">
                            Zoom In
                        </Button>
                        <Button onClick={handleZoomOut} variant="contained">
                            Zoom Out
                        </Button>
                        <Button
                            onClick={handleCloseDialog}
                            variant="contained"
                            color="secondary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
);

export default MultiSubplotsStacked;
