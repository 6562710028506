/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "../styles/inActivePopUp.css";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import apiAbmBlaze, { UPLOAD_CSV_ENCRYPTED_FILE } from "../../api";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  InsertDriveFileOutlined,
  PauseCircleOutline,
  CheckCircleOutlined,
} from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // Main style
import "react-date-range/dist/theme/default.css"; // Theme
import { Button, Popover, Box, TextField, Typography } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";

const UploadCSV = (props) => {
  const [filedata, setFiledata] = React.useState("");
  const [CsvResponse, setCSVResponse] = React.useState(false);
  const [uploadcsvHide, setUploadcsvHide] = React.useState(true);
  const [uploadingerorr, setUploadingerror] = React.useState(false);
  const [decryptedCsvData, setDecryptedCsvData] = React.useState("");
  const [rawDecryptFileData, setRawDecryptedFileData] = React.useState("");
  const [uuid, setUuid] = React.useState("");
  const [summary, setSummary] = React.useState();
  const [range, setRange] = React.useState({});
  const [startfrom, setStartfrom] = React.useState(false);
  const hiddenFileInput = React.useRef(null);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const history = useHistory();
  const [processing, setProcessing] = React.useState(false); // Processing state
  const [uploadProgress, setUploadProgress] = React.useState(0); // Upload progress
  const [fileUploaded, setFileUploaded] = React.useState(false); // File upload completion state
  const [processStep, setProcessStep] = React.useState(0); // Process step index
  const [fileDetails, setFileDetails] = React.useState(null);

  // Resetting state so that, need not be refreshed to decrypt another file
  useEffect(() => {
    if (props.isopen) {
      setFiledata("");
      setCSVResponse(false);
      setUploadcsvHide(true);
      setUploadingerror(false);
      setDecryptedCsvData("");
      setStartfrom(false);
    }
  }, [props.isopen]);
  const handleFileCSV = (e) => {
    simulateUpload();
    const file = e.target.files?.[0];
    if (!file) return;

    setFiledata([file]);
    setUploadingerror(false);
    const fileName = file.name;
    const [deviceId, date, time] = fileName.replace(/_system$/, "").split("_");
    if (!deviceId || !date || !time) {
      console.error("Invalid file name format");
      return;
    }

    setFileDetails({
      fileName,
      deviceId,
      deviceType: deviceId.startsWith("C") ? "Biwaze Cough" : "Biwaze Clear",
      date,
      time,
    });
  };

  useEffect(() => {
    if (!filedata) {
      return;
    }
    // setCSVResponse(true);
    // setUploadcsvHide(false);
    fetchData();
  }, [filedata]);
  const fetchData = () => {
    let GetCSVFile = filedata[0];
    let formData = new FormData();
    formData.append("uploaded-csv", GetCSVFile);
    let url = `/v2/load_existing_csv?uuid=${uuid}`;

    if (range?.from_date) {
      url += `&from_date=${range?.from_date}`;
    }

    if (range?.to_date) {
      url += `&to_date=${range?.to_date}`;
    }
    const baseURL = `${UPLOAD_CSV_ENCRYPTED_FILE}`;
    console.log(uuid);
    apiAbmBlaze
      .post(uuid ? url : baseURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setCSVResponse(false);
        if (response && response.data) {
          if (!response.data.uuid) {
            setRawDecryptedFileData(response.data);
            setDecryptedCsvData(null);
          } else {
            if (response.data.summary) {
              setSummary(response.data.summary);
            }
            const formattedDate = new Date(
              fileDetails.date.slice(4, 8),
              parseInt(fileDetails.date.slice(2, 4)) - 1,
              fileDetails.date.slice(0, 2)
            ).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            });
            const newRows = [
              `Serial Number: ${fileDetails.deviceId}`, // Row 1
              `Date: ${formattedDate}`, // Row 1
            ];
            setDecryptedCsvData([...newRows, response.data.csv].join("\n"));
            setUuid(response.data.uuid);
            setRawDecryptedFileData(null);
          }
        }
      })
      .catch((error) => {
        if (error) {
          setCSVResponse(false);
          setUploadingerror(true);
          setUploadcsvHide(true);
        }
      });
  };
  const DownloadCsv = (e) => {
    setStartfrom(true);
    if (decryptedCsvData || rawDecryptFileData) {
      const url = window.URL.createObjectURL(
        new Blob([decryptedCsvData ?? rawDecryptFileData], {})
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = `Decrypted_${filedata[0].name}`;
      link.click();
      window.URL.revokeObjectURL(url);
      // setTimeout(() => closingInto(), 1000);
    }
  };
  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  const closingInto = () => {
    props.closePopup();
    if (startfrom) {
      setUploadcsvHide(true);
    }
    setFileUploaded(false);
    setUploadProgress(0);
    setProcessing(false);
    setDecryptedCsvData(null);
    setRawDecryptedFileData(null);
    setUuid(null);
    setSummary(null);
    setRange(null);
  };
  const steps = [
    "🔒 Encrypted with top-tier security!",
    "🔍 Validating your data...",
    "🔓 Decrypting the data...",
    "📊 Extracting therapy insights...",
    "🛠️ Building your report...",
    "🎉 Final touches in progress!",
    "✨ Simplifying for easy use...",
    "📥 Looks Like data is large please wait...",
  ];

  // Simulate file upload
  const simulateUpload = () => {
    setUploadProgress(0); // Reset progress
    setFileUploaded(false); // Reset file uploaded status

    const uploadStep = () => {
      if (uploadingerorr) {
        console.error("Upload error detected. Stopping simulation.");
        setFileUploaded(false);
        return; // Stop further execution if an error occurs
      }

      setUploadProgress((prev) => {
        const nextValue = prev + 5;

        if (nextValue >= 100) {
          setFileUploaded(true); // Mark upload as complete
          return 100; // Cap progress at 100
        }

        // Schedule the next step
        setTimeout(uploadStep, 500);
        return nextValue;
      });
    };

    // Start the upload simulation
    setTimeout(uploadStep, 500);
  };

  // Trigger file explorer
  const handleBrowseClick = () => {
    hiddenFileInput.current.click();
  };

  // Start processing steps
  const startProcessing = () => {
    setProcessing(true);
    let step = 0;

    const processInterval = setInterval(() => {
      if (!fileUploaded) return;

      if (uuid) {
        setProcessStep(steps.length - 1);
      } else if (step < steps.length - 1) {
        setProcessStep((prev) => prev + 1);
        step++;
      } else {
        clearInterval(processInterval);
      }
    }, 1500);
  };

  const DateRangePicker = ({ setRange, range }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [localRange, setLocalRange] = React.useState({
      from: range?.from_date,
      to: range?.to_date,
    });

    const handleSubmit = () => {
      setRange({ from_date: localRange?.from, to_date: localRange?.to });
      setAnchorEl(null);
    };
    const handleClear = () => {
      setRange({ from_date: null, to_date: null });
      setAnchorEl(null);
    };

    return (
      <>
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={{
            margin: "8px 0", // Adds some vertical spacing
            padding: "8px 16px", // Adds padding for better clickability
          }}
          sx={{
            color: "#1461D3 !important", // Button text color
            backgroundColor: "#f0f8ff", // Light background for better visibility
            border: "1px solid #1461D3", // Adds a border for emphasis
            borderRadius: "8px", // Smooth rounded corners
            display: "flex", // Aligns text and icon horizontally
            alignItems: "center", // Centers content vertically
            gap: "8px", // Adds space between text and icon
            "&:hover": {
              backgroundColor: "#e6f0ff", // Slightly darker hover effect
              color: "#104eb2 !important", // Adjust hover text color
            },
          }}
        >
          {range?.from_date || "Select Start Date"}
          {range?.to_date && ` to ${range?.to_date}`}{" "}
          {/* Conditionally show "to" and `to_date` */}
          <CalendarToday style={{ fontSize: "18px" }} />{" "}
          {/* Adjust icon size */}
        </Button>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Box sx={{ p: 2, width: "300px" }}>
            <Typography variant="h6" sx={{ mb: 2, fontSize: "16px" }}>
              Select Date Range
            </Typography>
            <TextField
              type="date"
              label="From"
              fullWidth
              value={localRange?.from || ""}
              onChange={(e) =>
                setLocalRange({ ...localRange, from: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 2 }}
            />
            <TextField
              type="date"
              label="To"
              fullWidth
              value={localRange?.to || ""}
              onChange={(e) =>
                setLocalRange({ ...localRange, to: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
            />
            <div className="flex">
              <Button
                variant="outlined"
                fullWidth
                sx={{ m: 2 }}
                onClick={handleClear}
                disabled={!localRange?.from && !localRange?.to}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{ m: 2 }}
                onClick={handleSubmit}
                disabled={!localRange?.from && !localRange?.to}
              >
                Apply
              </Button>
            </div>
          </Box>
        </Popover>
      </>
    );
  };
  return (
    <>
      <AlertSnackBar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cupSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />

      <div className="media-patient upload-csv">
        <Dialog
          open={props.isopen}
          onClose={closingInto}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title media-patient">
            <div className="Ptient-heading-container">
              <div className="patient-title">
                {/* Upload Section */}
                <h5
                  style={{
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: "600",
                    color: "#4a4a4a",
                    marginBottom: "20px",
                    padding: "6px",
                  }}
                >
                  Upload Encrypted CSV
                </h5>
              </div>
              <div className="iconForCancelptient">
                <CloseIcon
                  onClick={closingInto}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </DialogTitle>
          {uploadcsvHide ? (
            <DialogContent className="media-patient">
              <DialogContentText id="alert-dialog-description">
                <div style={{ height: "250px" }}>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileCSV}
                    style={{ display: "none" }}
                    accept=".csv"
                  />
                  {!processing ? (
                    <>
                      {!uploadProgress && (
                        <div
                          className="border border-dashed p-4 text-center"
                          style={{
                            borderColor: "#d1e5fc",
                            borderRadius: "12px",
                            backgroundColor: "#f9fcff",
                            marginBottom: "20px",

                            transition:
                              "opacity 0.3s ease, transform 0.3s ease", // Smooth transitions
                          }}
                        >
                          <InsertDriveFileOutlined
                            onClick={handleBrowseClick}
                            style={{
                              fontSize: "48px",
                              color: "#2979ff",
                            }}
                          />
                          <p
                            style={{
                              fontSize: "12px",
                              color: "#2979ff",
                              fontWeight: "bold",
                            }}
                          >
                            CSV
                          </p>

                          <p className="text-muted">
                            Drop your encrypted CSV file here, or{" "}
                            <span
                              onClick={handleBrowseClick}
                              style={{
                                color: "#2979ff",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                            >
                              browse
                            </span>
                          </p>
                        </div>
                      )}

                      {uploadProgress > 0 && (
                        <>
                          <div>
                            {fileDetails && (
                              <>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    margin: "10px 0",
                                  }}
                                >
                                  Thanks for using {fileDetails.deviceType}
                                </p>
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: "10px 15px",
                                      background: "#f9f9f9",
                                      borderRadius: "8px",
                                      boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                                      fontSize: "12px",
                                      lineHeight: "1.5",
                                      maxWidth: "600px",
                                      margin: "10px auto",
                                    }}
                                  >
                                    <span>
                                      Serial:{" "}
                                      <span style={{ color: "#1e88e5" }}>
                                        {fileDetails.deviceId}
                                      </span>
                                    </span>
                                    <span>
                                      Date:{" "}
                                      <span style={{ color: "#1e88e5" }}>
                                        {`${fileDetails.date.slice(
                                          0,
                                          2
                                        )}-${fileDetails.date.slice(
                                          2,
                                          4
                                        )}-${fileDetails.date.slice(4)}`}
                                      </span>
                                    </span>
                                    <span>
                                      Time:{" "}
                                      <span style={{ color: "#1e88e5" }}>
                                        {`${fileDetails.time.slice(
                                          0,
                                          2
                                        )}:${fileDetails.time.slice(
                                          2,
                                          4
                                        )}:${fileDetails.time.slice(4)}`}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "0 20px",
                                padding: "5px",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    fontFamily: "'Inter', sans-serif",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#4a4a4a",
                                    padding: "2px",
                                  }}
                                >
                                  {uploadProgress < 100 && !uploadingerorr ? (
                                    <div>
                                      Uploading... {fileDetails.fileName}
                                      <div className="loading-dots">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                      </div>
                                    </div>
                                  ) : uploadingerorr ? (
                                    "Error File Contents"
                                  ) : (
                                    "Click the button below to Continue"
                                  )}
                                </p>
                                {/* <p
                                                                style={{
                                                                    fontFamily: "'Inter', sans-serif",
                                                                    fontSize: "12px",
                                                                    fontWeight: "400",
                                                                    color: "#9ba9c5",
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                {uploadProgress}% • {timeRemaining} seconds left
                                                            </p> */}
                              </div>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <button
                                  className="btn btn-icon"
                                  style={{
                                    border: "none",
                                    backgroundColor:
                                      uploadProgress > 70
                                        ? uploadProgress < 100
                                          ? "#e6e6e6"
                                          : "#c0c0c0"
                                        : "#f0f4fd", // Dynamic color
                                    borderRadius: "50%",
                                    padding: "10px",
                                    cursor:
                                      uploadProgress > 70
                                        ? "not-allowed"
                                        : "pointer", // Change cursor
                                    visibility:
                                      uploadProgress > 10
                                        ? "hidden"
                                        : "visible", // Make invisible after 10%
                                    transition:
                                      "visibility 0.3s ease, opacity 0.3s ease", // Smooth transition
                                    opacity: uploadProgress > 10 ? 0 : 1, // Fade out
                                  }}
                                  disabled={uploadProgress > 70}
                                >
                                  <PauseCircleOutline
                                    style={{
                                      fontSize: "20px",
                                      color: "#9ba9c5",
                                    }}
                                  />
                                </button>

                                {/* <input
                                                                type="file"
                                                                ref={hiddenFileInput}
                                                                onChange={handleFileCSV}
                                                                style={{ display: "none" }}
                                                                accept=".csv"
                                                            /> */}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {/* <ShimmerProgressBar uploadProgress={uploadProgress} /> */}
                            {/* <div>
                                                        <button
                                                            className="btn btn-icon"
                                                            onClick={cancelUpload}
                                                            style={{
                                                                border: "none",
                                                                background: "none", // Remove background for a minimal look
                                                                borderRadius: "50%",
                                                                transition:
                                                                    "visibility 0.3s ease, opacity 0.3s ease, cursor 0.3s ease", // Smooth animation for hover/disable
                                                            }}
                                                        // disabled={uploadProgress > 70}
                                                        >
                                                            <CancelOutlined
                                                                style={{ fontSize: "22px", color: "#738490" }}
                                                            />
                                                        </button>
                                                    </div> */}
                          </div>
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end", // Align content to the right
                          marginTop: "20px", // Add some spacing if needed
                        }}
                      >
                        {rawDecryptFileData ? (
                          <button
                            onClick={DownloadCsv}
                            style={{
                              border: "1px solid #d1e5fc", // Subtle border
                              backgroundColor: fileUploaded
                                ? "#f4f9ff"
                                : "rgb(237 238 240)", // Softer background
                              borderRadius: "8px", // Slightly rounded corners
                              padding: "12px 16px", // Adjusted padding for a balanced look
                              color: !fileUploaded ? "#a2b6d6" : "#2979ff", // Modern blue color for text
                              fontWeight: "600", // Bold font for better visibility
                              fontFamily: "'Inter', sans-serif", // Clean font style
                              display: "flex", // Align icon and text
                              alignItems: "center", // Vertical alignment
                              gap: "8px", // Space between icon and text
                              cursor: "pointer", // Pointer for interactivity
                              transition: "all 0.3s ease", // Smooth transition for hover
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                            }}
                          >
                            Download CSV
                          </button>
                        ) : (
                          <button
                            onClick={startProcessing}
                            disabled={!fileUploaded || uploadingerorr}
                            style={{
                              border: "1px solid #d1e5fc",
                              backgroundColor:
                                !fileUploaded || uploadingerorr
                                  ? "rgb(237, 238, 240)"
                                  : "#f4f9ff",
                              borderRadius: "8px",
                              padding: "12px 16px",
                              color:
                                !fileUploaded || uploadingerorr
                                  ? "#a2b6d6"
                                  : "#2979ff",
                              fontWeight: "600",
                              fontFamily: "'Inter', sans-serif",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              cursor:
                                !fileUploaded || uploadingerorr
                                  ? "not-allowed"
                                  : "pointer",
                              transition: "all 0.3s ease",
                              boxShadow:
                                !fileUploaded || uploadingerorr
                                  ? "none"
                                  : "0 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            Proceed
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Processing Section */}
                      <div
                        className="container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          fontFamily: "'Inter', sans-serif",
                        }}
                      >
                        {!decryptedCsvData && (
                          <>
                            {" "}
                            <div>
                              {!decryptedCsvData && (
                                <CircularProgress
                                  style={{
                                    color: "#2979ff",
                                    animation: "rotate 1.5s linear infinite", // Subtle loading animation
                                  }}
                                />
                              )}

                              {decryptedCsvData && (
                                <CheckCircleOutlined
                                  style={{
                                    color: "#4caf50",
                                    fontSize: "38px",
                                    animation: "bounce 1s infinite", // Bounce animation for success
                                  }}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#4a4a4a",
                              }}
                            >
                              {steps[processStep]}
                            </div>
                          </>
                        )}
                        {decryptedCsvData && (
                          <>
                            {" "}
                            <div className="flex align-center justify-center gap-2">
                              <DateRangePicker
                                setRange={setRange}
                                range={range}
                              />
                              <button
                                onClick={fetchData}
                                disabled={!(range?.from_date || range?.to_date)}
                                style={{
                                  border: "1px solid #d1e5fc", // Subtle border
                                  backgroundColor: fileUploaded
                                    ? "#f4f9ff"
                                    : "rgb(237 238 240)", // Softer background
                                  borderRadius: "8px", // Slightly rounded corners
                                  padding: "0 6px", // Adjusted padding for a balanced look
                                  color: !fileUploaded ? "#a2b6d6" : "#2979ff", // Modern blue color for text
                                  fontWeight: "600", // Bold font for better visibility
                                  fontFamily: "'Inter', sans-serif", // Clean font style
                                  display: "flex", // Align icon and text
                                  alignItems: "center", // Vertical alignment
                                  margin: "5px", // Space between icon and text
                                  cursor: "pointer", // Pointer for interactivity
                                  transition: "all 0.3s ease", // Smooth transition for hover
                                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                                }}
                              >
                                Fetch
                              </button>
                            </div>
                            {summary &&
                              summary?.startDate &&
                              summary?.endDate && (
                                <div className="flex align-center justify-center gap-2">
                                  Therapies found from {summary.startDate} till{" "}
                                  {summary.endDate}
                                </div>
                              )}
                            <div
                              style={{
                                marginTop: "30px",
                                display: "flex",
                                justifyContent: "center",
                                gap: "15px",
                              }}
                            >
                              <button
                                onClick={DownloadCsv}
                                style={{
                                  border: "1px solid #d1e5fc",
                                  backgroundColor: fileUploaded
                                    ? "#f4f9ff"
                                    : "rgb(237, 238, 240)",
                                  borderRadius: "10px",
                                  padding: "12px 20px",
                                  color: fileUploaded ? "#2979ff" : "#a2b6d6",
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  cursor: fileUploaded
                                    ? "pointer"
                                    : "not-allowed",
                                  transition: "box-shadow 0.3s ease",
                                  boxShadow: fileUploaded
                                    ? "0 4px 8px rgba(0, 0, 0, 0.1)"
                                    : "none",
                                }}
                              >
                                Download CSV
                              </button>

                              {fileDetails.deviceType === "Biwaze Cough" && (
                                <button
                                  onClick={() => {
                                    history.push(
                                      `/decrypt?uuid=${uuid}&id=${fileDetails.deviceId}`
                                    );
                                  }}
                                  disabled={!fileUploaded}
                                  style={{
                                    border: "1px solid #d1e5fc",
                                    backgroundColor: fileUploaded
                                      ? "#f4f9ff"
                                      : "rgb(237, 238, 240)",
                                    borderRadius: "10px",
                                    padding: "12px 20px",
                                    color: fileUploaded ? "#2979ff" : "#a2b6d6",
                                    fontWeight: "600",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    cursor: fileUploaded
                                      ? "pointer"
                                      : "not-allowed",
                                    transition: "box-shadow 0.3s ease",
                                    boxShadow: fileUploaded
                                      ? "0 4px 8px rgba(0, 0, 0, 0.1)"
                                      : "none",
                                  }}
                                >
                                  Proceed to Dashboard
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </DialogContentText>
              {uploadingerorr ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "400",
                    textAlign: "center",
                    display: "block",
                    marginTop: "10px",
                  }}
                >
                  Something Went Wrong, Please Try Again!
                </span>
              ) : null}
            </DialogContent>
          ) : CsvResponse && !uploadcsvHide ? (
            <></>
          ) : (
            <div className="media-patient" style={{ height: "280px" }}>
              <p
                style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  color: "#646a8d",
                  marginTop: "50px",
                  fontSize: "20px",
                }}
              >
                Select Download to save the decrypted file in a csv format.
                <br />
              </p>
              <div
                class="upload-btn-wrapper"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "50px",
                }}
              >
                <button class="btn2" onClick={(e) => DownloadCsv(e)}>
                  <i class="fa fa-download" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Download CSV
                </button>
                <input type="button" name="myfile" />
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </>
  );
};
export default UploadCSV;
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AlertSnackBar = ({
  flag,
  onClose,
  anchorOrigin,
  msg,
  severity,
}) => {
  return (
    <Snackbar
      anchorOrigin={
        anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "right" }
      }
      open={flag}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity ? severity : "success"}>
        {msg}
      </Alert>
    </Snackbar>
  );
};
