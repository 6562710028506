/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import TherapyTable from "./therapy-table";
import MultiSubplotChart from "./group";
import {
    Drawer, Button,
    Popover, Box, TextField, Typography, CircularProgress
} from "@mui/material";
import { Tooltip } from '@mui/material';
import { ViewList, FilePresentTwoTone } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import logoL from './arc.png'
import { useHistory } from "react-router-dom";
import apiAbmBlaze from './../../../api/index'
import { ResizableBox } from 'react-resizable';
import "react-resizable/css/styles.css";
import { Add } from '@mui/icons-material';
import { CalendarToday } from "@mui/icons-material";
import Loader from "./loader";

export const ReportPage = () => {
    // eslint-disable-next-line no-unused-vars
    const [activeTab, setActiveTab] = useState("Overview");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [data, setData] = useState([]);
    const [graphData, setGraphData] = useState(null);
    const [averageValues, setAverageValues] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loadingAutoPdf, setLoadingAutoPdf] = useState(false);
    const [hidePreview, setHidePreview] = useState(false); // Toggle for hidePreview
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const childRef = useRef(); // Create a ref for the child component
    const history = useHistory();
    const [therapiesPerGraph, setTherapiesPerGraph] = useState(300); // Default to "All"
    const [loading, setLoading] = useState(true);
    const handleGraphLoading = () => {
        setLoading(false);
    };
    const handleSelectChange = (event) => {
        setTherapiesPerGraph(event.target.value);
        getGraphPoints(selectedRows)
        // Update state with selected value
    };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filename = queryParams.get("uuid");
    const serialNumber = queryParams.get("id");
    const DateRangePicker = ({ onDateRangeSelect }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [localRange, setLocalRange] = useState({ from: dateRange.from, to: dateRange.to });

        const handleSubmit = () => {
            onDateRangeSelect({ from: localRange.from, to: localRange.to });
            setAnchorEl(null);
        };
        const handleClear = () => {
            onDateRangeSelect({ from: null, to: null });
            setAnchorEl(null);
        };

        return (
            <div>
                <Button
                    style={{ margin: 0, padding: 0 }}
                    // variant="contained"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    sx={{
                        // backgroundColor: "#1461D3",
                        color: "#1461D3",
                        // "&:hover": { backgroundColor: "#104eb2" },
                    }}
                >
                    <CalendarToday />
                </Button>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Box sx={{ p: 2, width: "300px" }}>
                        <Typography variant="h6" sx={{ mb: 2, fontSize: "16px" }}>
                            Select Date Range
                        </Typography>
                        <TextField
                            type="date"
                            label="From"
                            fullWidth
                            value={localRange.from || ""}
                            onChange={(e) =>
                                setLocalRange({ ...localRange, from: e.target.value })
                            }
                            InputLabelProps={{ shrink: true }}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            type="date"
                            label="To"
                            fullWidth
                            value={localRange.to || ""}
                            onChange={(e) =>
                                setLocalRange({ ...localRange, to: e.target.value })
                            }
                            InputLabelProps={{ shrink: true }}
                        />
                        <div className="flex">
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ m: 2 }}
                                onClick={handleClear}
                                disabled={(!localRange.from) && (!localRange.to)}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ m: 2 }}
                                onClick={handleSubmit}
                                disabled={(!localRange.from) && (!localRange.to)}
                            >
                                Apply
                            </Button></div>
                    </Box>
                </Popover>
            </div>
        );
    };

    useEffect(() => {
        setIsDrawerOpen(activeTab === "Overview");
    }, [activeTab]);
    // const baseUrl = process.env.API_URL ?? ""
    // const baseUrl = "http://localhost:6000"
    // const baseUrl = "https://data1.arc.abmrc.com"
    useEffect(() => {
        if (filename) {
            apiAbmBlaze
            .get(`/v2/load-data?filename=${filename}`)
                .then((response) => {
                    const therapyData = JSON.parse(response?.data?.data?.therapy_start);
                    // setDateRange({ from: therapyData[0]["Time Stamp"]?.split(" ")[0] ?? null, to: therapyData[therapyData.length - 1]["Time Stamp"]?.split(" ")[0] ?? null })
                    setData([...therapyData]);
                    getGraphPoints([])
                })
                .catch((error) => console.error("Error fetching data:", error));
        }
    }, [filename]);

    const handleSelectRow = (row) => {
        setSelectedRows((prevSelected) => {
            const newRows = prevSelected.includes(row["Event ID"])
                ? prevSelected.filter((id) => id !== row["Event ID"])
                : [...prevSelected, row["Event ID"]];

            getGraphPoints(newRows)

            return newRows;
        });
    };
    const getGraphPoints = (newRows, dateSelected = { from: null, to: null }) => {
        let url = `/v2/get-graph-points?uuid=${filename}&selected_rows=${newRows}`;

        if (dateSelected.from) {
            url += `&from_date=${encodeURIComponent(dateSelected.from)}`;
        }

        if (dateSelected.to) {
            url += `&to_date=${encodeURIComponent(dateSelected.to)}`;
        }
        apiAbmBlaze
            .get(
                `${url}`
            )
            .then((response) => {
                const therapyGraphData = response?.data?.merged_data;
                setGraphData({ ...therapyGraphData });
                let summaryUrl = `/v2/get-summary?uuid=${filename}`;
            
                if (dateSelected.from) {
                    summaryUrl += `&start_date=${encodeURIComponent(dateSelected.from)}`;
                }
    
                if (dateSelected.to) {
                    summaryUrl += `&end_date=${encodeURIComponent(dateSelected.to)}`;
                }
                apiAbmBlaze
                    .get(`${summaryUrl}`)
                 
                    .then((response) => {
                        setAverageValues(response?.data)
                    }).catch((error) => console.error("Error fetching Average Summary:", error));

                // setDateRange({ from: therapyGraphData["Cycle_E_P"][0]?.x[0], to: therapyGraphData["Cycle_E_P"][0]?.x[therapyGraphData["Cycle_E_P"][0]?.x.length - 1] })
            })
            .catch((error) => console.error("Error fetching graph data:", error));
    }
    const addToReport = () => {
        setLoadingAutoPdf(true);
        try {
            if (childRef.current) {
                childRef.current.addToReport();
            }
        } catch (error) {
            console.error("Error generating PDF report:", error);
            // alert("Failed to generate PDF report. Please try again.");
        }
    }
    const handleAutoGeneratePdf = async () => {
        try {
            if (childRef.current) {
                childRef.current.handleParentReport();
            }
        } catch (error) {
            console.error("Error generating PDF report:", error);
            // alert("Failed to generate PDF report. Please try again.");
        }
    };
    const handleSetDateRange = ({ from, to }) => {
        setDateRange({ from, to })
        getGraphPoints(selectedRows, { from, to })
    }
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "8px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    position: "fixed", // Fixed position
                    top: "0", // Stick to the top
                    left: "0", // Full-width from the left
                    right: "0", // Full-width to the right
                    zIndex: "1000", // Ensure it appears above other content
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "16px",
                        flex: 1, // Center content in the top bar
                    }}
                >
                    {/* Logo */}
                    <Box
                        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={() => {
                            history.push(`/`);
                        }}
                    >
                        <img
                            src={logoL}
                            alt="Logo"
                            style={{ width: "50px", height: "50px", margin: "2px" }}
                        />
                    </Box>
                    <Box style={{ textAlign: "center" }}>
                        <Typography
                            variant="h5"
                            component="h5"
                            sx={{ flexGrow: 1, color: "#333", fontWeight: "bold" }}
                        >
                            {serialNumber} Therapy Report
                        </Typography>
                        <small style={{ color: "#666" }}>
                            {dateRange.from && dateRange.to
                                ? `${dateRange.from} to ${dateRange.to}`
                                : dateRange.from
                                    ? `From ${dateRange.from}`
                                    : dateRange.to
                                        ? `Up to ${dateRange.to}`
                                        : ""}
                        </small>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                    }}
                    onMouseEnter={() => {
                        console.log("Hover Enter");
                        setHidePreview(true); // Call setHiderPreview with true on hover
                    }}
                >
                    <Button
                        variant="outlined"
                        startIcon={<FilePresentTwoTone />}
                        onClick={() => handleAutoGeneratePdf()}
                        sx={{
                            height: "50px",
                            borderColor: "#94D2BD",
                            fontSize: "16px",
                            fontWeight: "bold",
                            borderRadius: "8px",
                            transition: "all 0.3s ease",
                        }}
                    >
                        Generate Report
                    </Button>
                </Box>

            </div>
            <Box sx={{ height: "80px" }} />
            <Box sx={{ backgroundColor: "#f4f6f9", padding: "10px", minHeight: "80vh" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                        alignConetent: "center",
                        padding: "4px",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Button
                        variant="outlined"
                        startIcon={<ViewList />}
                        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                        aria-label="View Therpaies"
                        sx={{
                            height: "40px",
                            margin: "10px",
                            borderColor: "#0A9396",
                        }}
                    >

                    </Button>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "#333", fontWeight: "bold" }}
                    >
                        Customize Your Report
                    </Typography>
                    <Typography
                        variant="body1"
                        component="div"
                        sx={{ color: "#555", lineHeight: "1.5" }}
                    >
                        1. Zoom in on the graph by selecting a specific area, if required.<br />
                        2. Click the "Copy to Report" button to save this view to the report.<br />
                        3. Click the "Generate Report" button to view/download your customized PDF.<br />
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row", // Arrange elements in a single row
                            gap: 2,
                            borderRadius: "12px",
                            backgroundColor: "#f8f9fa",
                            alignItems: "center", // Ensure vertical alignment
                            flexWrap: "wrap", // Ensure responsiveness for smaller screens
                        }}
                    >
                        Date Range
                        <DateRangePicker onDateRangeSelect={handleSetDateRange} />
                    </Box>

                    <Tooltip
                        title="Copies current view to the report"
                        arrow
                        placement="left"
                    >
                        <Box display="flex" alignItems="center" marginRight={"15px"} gap={1}>
                            {loadingAutoPdf ? (
                                <Button
                                    variant="outlined"
                                    startIcon={<CircularProgress size={20} sx={{ color: "#94D2BD" }} />}
                                    sx={{
                                        height: "38px",
                                        borderColor: "#94D2BD",
                                        padding: "8px 24px",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        borderRadius: "8px",
                                        transition: "all 0.3s ease",
                                        "&:hover": {
                                            backgroundColor: "#EFF8F6",
                                            borderColor: "#94D2BD",
                                        },
                                        "&:disabled": {
                                            borderColor: "#ddd",
                                            color: "#ddd",
                                        },
                                    }}
                                    disabled
                                >
                                    Adding to report...
                                </Button>
                            ) : (<Button
                                onClick={addToReport}
                                variant="contained"
                                startIcon={
                                    <Add sx={{ fontSize: "20px", transition: "transform 0.3s ease" }} />
                                }
                            >
                                Add graph to Report
                            </Button>)}
                        </Box>
                    </Tooltip>
                </Box>
                <Drawer anchor="left" onClose={() => setIsDrawerOpen(false)}>
                    <Box sx={{ width: "300px", padding: "20px" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <Typography variant="h6">Therapy Table</Typography>
                            <CloseOutlined
                                onClick={() => setIsDrawerOpen(false)}
                                sx={{ cursor: "pointer", color: "#333" }}
                            />
                        </Box>
                        {data.length > 0 ? (

                            <TherapyTable
                                dateRange={dateRange}
                                handleSetDateRange={handleSetDateRange}
                                data={data}
                                handleSelectRow={handleSelectRow}
                                selectedRows={selectedRows}
                            />
                        ) : (
                            <Typography>No data available</Typography>
                        )}
                        <Button
                            variant="contained"
                            onClick={() => setIsDrawerOpen(false)}
                            sx={{
                                marginTop: "20px",
                                backgroundColor: "#1461D3",
                                "&:hover": { backgroundColor: "#104eb2" },
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </Drawer>
                {loading && <Loader />}

                <div className="flex">
                    {isDrawerOpen &&
                        <ResizableBox
                            width={200}
                            height={500}
                            axis="x"
                            minConstraints={[100, 200]}
                            maxConstraints={[500, Infinity]}
                            resizeHandles={["e"]}
                            style={{ width: "4vw", paddingRight: "10px" }}
                        >
                            <div style={{ height: "100%", overflow: "auto" }}>
                                {graphData && (<TherapyTable
                                    dateRange={dateRange}
                                    handleSetDateRange={handleSetDateRange}
                                    data={data}
                                    handleSelectRow={handleSelectRow}
                                    selectedRows={selectedRows}
                                />)}
                            </div>
                        </ResizableBox>
                    }
                    <Box
                        sx={{
                            backgroundColor: "#fff",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#f8f9fa",
                                borderRadius: "8px",
                            }}
                        >
                            {graphData && (<MultiSubplotChart
                                setLoadingAutoPdf={setLoadingAutoPdf}
                                data={graphData}
                                totalTherpies={data?.length}
                                startDate={dateRange.from}
                                endDate={dateRange.to}
                                hidePreview={hidePreview}
                                ref={childRef}
                                averageValues={averageValues}
                                serialNumber={serialNumber}
                                therapiesPerGraph={therapiesPerGraph}
                                handleSelectChange={handleSelectChange}
                                setHidePreview={setHidePreview}
                                handleGraphLoading={handleGraphLoading}
                            />)}
                        </Box>
                    </Box>
                </div>

            </Box>
        </>
    );
};

export default ReportPage;
